import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DeepVueCredentials } from './documentverification/documentverificationendpoints';
import { ToastrService } from 'ngx-toastr';
import { AppInterceptorService } from '../../shared/app-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  apiUrl = environment.isDummyDataSource ? environment.dummyAPIUrl + '/' : environment.apiEndpoint + '/api/'

  constructor(private http: HttpClient, private toastr: ToastrService, private appinterceptorService: AppInterceptorService) {
  }

  private getHeaders(isDocVerifyReq?: boolean, isDoc?: boolean): HttpHeaders {
    if (isDocVerifyReq) {
      const documentVerificationToken = localStorage.getItem('mbcrm/docVerificationToken') || '';
      return new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Access-Control-Allow-Origin', '*')
        .set('Authorization', `Bearer ${documentVerificationToken}`)
        .set('x-api-key', DeepVueCredentials.client_secret);
    } else if (isDoc) {
      const locallyStoredToken = localStorage.getItem('mbcrm/token') || '';
      return new HttpHeaders()
        .set('Authorization', `Bearer ${locallyStoredToken}`);
    }
    else {
      const locallyStoredToken = localStorage.getItem('mbcrm/token') || '';
      return new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('Authorization', `Bearer ${locallyStoredToken}`);
    }
  }


  private handleError(errorMessages?: string) {
    let commonErrorMessage = 'Unknown error occurred.';
    this.toastr.error(errorMessages ? errorMessages : commonErrorMessage);
    return throwError(errorMessages ? errorMessages : commonErrorMessage);
  }

  /**
   * Gets Data from API based on API Url endpoints
   * @param urlName API URL from where data has to be fethed
   * @param isDocVerifyReq Checks if current request is for document verification API
   * @returns response from API
   */
  getData(urlName: string, isDocVerifyReq?: boolean, errorMessages?: string): Observable<any> {
    const headers = this.getHeaders(isDocVerifyReq);
    if (isDocVerifyReq) {
      //If current api is called foer document verification then check from Doc Verification API
      //Else to complete request from our own API then check data source API/Dymmy
      return this.http.get<any>(`${this.apiUrl}${urlName}`, { headers })
        .pipe(catchError(error => this.handleError(errorMessages)));
    }
    else {
      if (environment.isDummyDataSource) {
        //IF getting data from MOCK API then headers are not required
        return this.http.get<any>(`${this.apiUrl}${urlName}`)
      }
      else {
        return this.http.get<any>(`${this.apiUrl}${urlName}`, { headers });
      }
    }
  }

  /**
   *api control for post operation
   * @param urlName : Url to which request has to be sent
   * @param data : Data need to be posted in api request
   * @param isDocVerifyReq: Checks if this request is for document verification API
   * @returns Response returned from API Req
   */
  postData(urlName: string, data: any, isDocVerifyReq?: boolean, isDoc?: boolean): Observable<any> {
    if (!urlName.toLowerCase().includes('login') && !this.appinterceptorService.validateToken()) {
      return throwError(() => new Error('Token expired'));
    }
    const headers = this.getHeaders(isDocVerifyReq, isDoc);
    //check if api is being called for document verification or not
    if (isDocVerifyReq) {
      return this.http.post<any>(`${urlName}`, data, { headers });
    } else {
      // if API is not called for document verification but for posting data then check
      // if it is posting data to actual API or MOCK API
      if (environment.isDummyDataSource) {
        //IF Posting data to MOCK API then headers are not required
        return this.http.post<any>(`${this.apiUrl}${urlName}`, data)
      }
      else {
        return this.http.post<any>(`${this.apiUrl}${urlName}`, data, { headers });
      }
    }
  }

  patchData(urlName: string, id?: any, data?: any, isDoc?: boolean): Observable<any> {
    const headers = this.getHeaders(false, isDoc);
    return this.http.patch<any>(`${this.apiUrl}${urlName}/${id}`, data, { headers });
  }
  // PUT request example
  /**
   * api control for put operation
   * @param urlName Url to which request has to be sent
   * @param id record id whose data has to be updated
   * @param data Data need to be posted in api request
   * @param isDocVerifyReq: Checks if this request is for document verification API
   * @returns Response returned from API Req
   */
  updateData(urlName: string, id: number, data: any, isDoc?: boolean): Observable<any> {
    const headers = this.getHeaders(false, isDoc);
    return this.http.put<any>(`${this.apiUrl}${urlName}/${id}`, data,
      { headers });
  }

  // DELETE request example
  /**
   * api control for delete operation
   * @param urlName
   * @param id
   * @returns
   */
  deleteData(urlName: string, id: number): Observable<any> {
    const headers = this.getHeaders();
    return this.http.delete<any>(`${this.apiUrl}${urlName}/${id}`,
      { headers });
  }

  /**
   * Search Bank details based on IFCC Code
   * @param ifscCode IFSC Code for which data has to be searched
   * @returns returns response returned from API
   */
  getBankDetailsByifsc(ifscCode: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(`${this.apiUrl + "bank-master/SearchIfsc?ifsc="}${ifscCode}`,
      { headers }
    );
  }

  getPlainFileContentFromUrl(url: string) {
    return this.http.get(url, { responseType: 'text' })
  }
}
