export enum UserSubType {
  User = 1,
  Agent = 2,
  Client = 3,
  Farmer = 4,
  SystemUser = 5,
}

export enum AccountTypeId {
  User = 1,
  Agent = 2,
  Client = 3,
  Farmer = 4,
  SystemUser = 5,
}

export enum AmountSubTypes {
  BasicSalePrice = "Basic Sale Price",
  PowerBackup = "Power Backup",
  MaintenanceCharges = "Maintenance Charges",
  ParkingCharges = "Parking Charges",
  ClubMembershipFee = "Club Membership Fee",
  ParkPLC = "Park PLC",
  CornerPLC = "Corner PLC",
  WideRoadPLC = "Wide Road PLC"

}

export enum DocumentSubType {
  Pan = 1,
  AadhaarFront = 2,
  Photo = 3,
  Passport = 4,
  DrivingLicense = 5,
  BankAccount = 6,
  VoterIdFront = 7,
  ApplicationForm = 8,
  Resume = 9,
  BGVerificationReport = 10,
  Khatauni = 11,
  Sajra = 12,
  Mutation = 13,
  ATS = 14,
  SaleDeed = 15,
  AadhaarBack = 16,
  PaymentReceiptsAllotment = 17,
  PaymentReceiptsAgent = 18,
  VoterIdBack = 19
}

export const enum DocumentTypes {
  KYC = 1,
  Agreements = 2,
  Application = 3,
  Property = 4,
  PaymentReceipts = 5,
  UID = 6
}

export const enum SourceType {
  Direct = 2
}

export const enum ExportedfileStyles {
  primaryColor = '#BA141A',
  secondaryColor = '#ffffff',
  headerRowColor = '#f8f8f8',
  evenRowColor = '#fcfcfc',
  oddRowColor = '#fff',
  pdfInnerBorderColor = '#dde2eb',
  pdfouterborderColor = '#babfc7',
}

export const enum ToasterMessage {
  successMessage = 'added Successfully',
  updateSuccessMessage = 'updated Successfully'
}

export const enum Base64FileFormats {
  JPEG = 'data:image/jpeg;base64,',
  PDF = '',
}

export const enum BrowsedFileFormats {
  JPEG = '.jpg, .jpeg, .png',
  PDF = '.pdf'
}

export const enum AssetsData {
  UserDefaultProfilePic = 'assets/images/profile.svg'
}

export enum AddressType {
  Current = 1,
  Permanent = 2,
  Office = 3,
  Shop = 4,
  Institute = 5,
  University = 6,
  Firm = 7
}
export enum AmountSubType {
  BaseAmount = 1,
  PowerBackup = 2,
  MaintenanceCharges = 3,
  ParkingCharges = 4

}

export enum commonListButton {
  ok = 'Done',
  cancel = 'Cancel'
}

export enum BloodGroup {
  APositive = 'A+',
  ANegative = 'A-',
  BPositive = 'B+',
  BNegative = 'B-',
  ABPositive = 'AB+',
  ABNegative = 'AB-',
  OPositive = 'O+',
  ONegative = 'O-'
}

export enum SaleMapColors {
  NotFound = '#808080',
  Allotted = '#FF0000',
  NotAllotted = '#0000FF'
}