import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';


@Injectable()
export class AppInterceptorService implements HttpInterceptor {

  constructor(private toastr: ToastrService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Add the site id Header only if data is not being fetched from actual api. For Mock API this header is not required
    if (!environment.isDummyDataSource) {
      //no need to verify token at the time of login
      if(!request.url.toLowerCase().includes('login')){
        if (!this.validateToken()) return throwError(() => new Error('Token expired'));
      }

      const siteId = localStorage.getItem('siteId');
      if (siteId) {
        request = request.clone({
          setHeaders: {
            siteId: siteId
          }
        });
      }
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'An error occurred';
        if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side errors
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        if (error.status == 401) {
          this.toastr.error('Invalid username or password.');
        } else {
          this.toastr.error('Something went wrong. Please contact to support team.');
        }
        return throwError(errorMessage);
      })
    );
  }


  validateToken(): boolean {
    const token = localStorage.getItem('mbcrm/token') || '';
    if (!token || this.isTokenExpired(token)) {
      this.handleTokenExpiration();
      return false;
    }
    return true;
  }


  private isTokenExpired(token: string): boolean {
    try {
      const { exp } = jwtDecode<{ exp: number }>(token);
      return Date.now() >= exp * 1000; // Check if the token is expired
    } catch (error) {
      return true; // Treat invalid tokens as expired
    }
  }

  private handleTokenExpiration() {
    localStorage.removeItem('mbcrm/token'); // Remove the token
    this.router.navigate(['login']); // Redirect to login page
  }
}
