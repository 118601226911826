import { Injectable } from "@angular/core";
import { RestService } from "../../services/common";
import { SaleMapColors } from "../../../appconfig";

@Injectable()
export class MapService {

    constructor(private restService: RestService) { }

    /**
     * Creates KML File string from a KML file by URL
     * @param url url of KML File whose KML string has to be created
     * @returns Returns KML String  
     */
    getKmlFileStringFromUrl(url: string) {
        return this.restService.getPlainFileContentFromUrl(url)
    }

    /**
     * Gets the KML String from a locally uploaded KML File
     * @param file KML file uploaded manually 
     * @returns Returns KML file content
     */
    getKmlFileFromLocalFile(file: any) {
        const reader = new FileReader();
        if (file) {

            reader.onload = (e: any) => {
                const kmlContent = e.target.result;
            }
        }

        return reader.readAsText(file)
    }

    /**
     * Parse a KML string into xml doc
     * @param kmlString KML string to parse
     * @returns Returns XML Doc parsed from KML String
     */
    parseKml(kmlString: string) {
        // Parse KML string into an XML document
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(kmlString, 'application/xml');
        // const polygon = xmlDoc?.querySelectorAll('Polygon');
        // Check for parse errors
        const parseError = xmlDoc.querySelector('parsererror');
        if (parseError) {
            console.error('Error parsing KML:', parseError.textContent);
            return;
        } else {
            return xmlDoc
        }
    }

    /**
     * Set the Polygon color based on it's status and availability 
     * @param polygondata Unit Data to find if polygon exist or not of that unit
     * @param searchValue Name of current Polygone which needs to be searched in polygine data
     * @returns Return a polygon to set on map with appropriate color
     */
    searchUnitPolygon(polygondata: any, polygoneName: string) {
        var polygon = null;

        //First check if polygon data has records
        if (polygondata) {
            //ittrate for each record and search if current polygone name exists for any unit 
            for (var index = 0; index < polygondata.length; index++) {
                var NameWithHyphen = polygondata[index]?.block?.toLowerCase() + '-' + polygondata[index]?.name?.toLowerCase()
                var NameWithUnderScore = polygondata[index]?.block?.toLowerCase() + '_' + polygondata[index]?.name?.toLowerCase()

                if (polygoneName?.toLowerCase() == NameWithHyphen || polygoneName?.toLowerCase() == NameWithUnderScore) {
                    //if found the polygon name in data then return that record 
                    polygon = polygondata[index]
                    return polygon
                }
            }
        }
        return polygon;
    }

    /**
     * Gets the polygon from a placemark 
     * @param placemark placemark from which polygon has to be fetched
     */
    getPlacemarkPolygon(placemark: any) {
        return placemark.querySelector('Polygon')
    }

    /**
     * Gets path of a Polygon's cordinates 
     * @param polygon Polygon whose cordinate's path has to be extracted
     */
    getPolygoneCordinatesPath(polygon: any) {
        //fetch cordinates of current polygon
        const cordinates = polygon.querySelectorAll('coordinates')[0]?.textContent.trim();

        // Convert coordinates to lat/lng
        const path = cordinates.split(' ').map((coord: any) => {
            const [lng, lat] = coord.split(',').map(Number);
            return lat != undefined ? { lat, lng } : 0;
        });

        return path

    }

    /**
     * Gets color Theme color for Sale Map
     * @param polygonStatus Status of polygon (found/not found/NA etc.)
     */
    GetPolygonColorforSaleMap(polygonRecord: any, kmlFor: string) {
        //Default color will assumed as not found
        var color = null;
        if (kmlFor.toLowerCase() == 'sales') {
            //bydefault color will be for Not Found
            color = SaleMapColors.NotFound
            //if current record is null means that polygon not found in polygon data array
            if (polygonRecord != null && polygonRecord != undefined) {
                //if record exists of that polygon then need to check if that unit is alloted or not to a client by 
                // checking "isAllotted" property of that record and assign colours accordingly
                if (polygonRecord?.isAllotted)
                    color = SaleMapColors.Allotted
                else
                    color = SaleMapColors.NotAllotted
            }
        }
        return color
    }

    /**
     * Gets HTML content to show on click of a polygon on map
     * @param kmlFor purpose of kml to decide if map has to be depicted for sale/purchase
     * @param unitData Data of current unit (plot/khasra) as per polygon name  
     * @param unitStatus Status of current polygon data found or not 
     * @returns returns HTML Content of currnt polygon
     */
    getHTMLContentForMap(kmlFor: any, unitData: any, unitStatus: any) {
        //Get HTML Content of unit for map as per unit status
        return this.restService.getPlainFileContentFromUrl('assets/map-html/' + kmlFor?.toLowerCase() + '/' +
            unitStatus.toLowerCase() + '-unit.html')
    }


    /**
     * Calculates the centroid of the polygon
     * @param path path cordinates of the polygon
     * @returns returns center points (lattitude, longitude) of the polygone 
     */
    getCentroid(path: any): { lat: number, lng: number } {
        // const latitudes = coords?.lat;
        // const longitudes = coords?.lng;

        // const lat = latitudes.reduce((a: any, b: any) => a + b, 0) / latitudes.length;
        // const lng = longitudes.reduce((a: any, b: any) => a + b, 0) / longitudes.length;

        // return { lat: lat, lng: lng };
        let centroid = { lat: 0, lng: 0 };

        let x = 0;
        let y = 0;
        let z = 0;

        // const xxx = this.georssLayer.getKmlObjects()

        // Loop through all the coordinates of the polygon
        path.forEach((latLng: any) => {
            const lat = latLng.lat;
            const lon = latLng.lng;

            // Convert lat/lon to Cartesian coordinates
            const latRad = this.toRadians(lat);
            const lonRad = this.toRadians(lon);

            x += Math.cos(latRad) * Math.cos(lonRad);
            y += Math.cos(latRad) * Math.sin(lonRad);
            z += Math.sin(latRad);
        });

        const totalPoints = path?.length
        x /= totalPoints;
        y /= totalPoints;
        z /= totalPoints;

        const lon = Math.atan2(y, x);
        const hyp = Math.sqrt(x * x + y * y);
        const lat = Math.atan2(z, hyp);

        centroid.lat = this.toDegrees(lat);
        centroid.lng = this.toDegrees(lon);

        return centroid;
    }

    private toDegrees(radians: number): number {
        return radians * (180 / Math.PI);
    }

    private toRadians(degrees: number): number {
        return degrees * (Math.PI / 180);
    }
}
