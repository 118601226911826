import { Injectable, Input } from '@angular/core';
import { RestService } from './rest.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BanksService {
  public isBankAccountExist: boolean = false;
  @Input() customerData: any;
  private bankAccountDetails = new BehaviorSubject<any>(null);
  private bankDetails = new BehaviorSubject<any>(null);
  constructor(private restService: RestService) {

  }

  //getting bankaccounts on behalf of userId
  public getBankAccountList(userId: any) {
    return this.restService.getData('bank-master/bankaccounts?userId=' + userId);
  }

  public getBankDetail() {
    return new Promise(response => this.restService.getData('bank-master').subscribe((res) => {
      response(res);
    }))
  }

  //observing the bank account details
  getAccountDetails() {
    return this.bankAccountDetails.asObservable();
  }

  //updating the bankAccount details
  setAccountDetails(data: any) {
    return this.bankAccountDetails?.next(data);
  }

  getBankDetails() {
    return this.bankDetails.asObservable();
  }

  //updating the bankAccount details
  setBanketails(data: any) {
    return this.bankDetails?.next(data);
  }

    /**
   * getting bank details by ifsc code
   * @param ifsc fetching bank details on behalf of this ifsc code
   * @returns
   */
    getBankDetailsByIfsc(ifsc:any){
      return this.restService.getData('bank-master/SearchIfsc?ifsc=' + ifsc);
    }

}
