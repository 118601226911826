
export class DocumentFileRequest {
    UserId!: number
    DocumentNumber!: string
    DocumentSubTypeId!: number
    Document: any
    IsActive!: boolean
    IsVerified!: boolean

    constructor() { }

    /**
     * Sets document file form data to be uploaded. It sets the request in form element type rather than JSON
     * @param documentForm JSON object of data to be uploaded
     * @returns 
     */
    setDocumentFormData(documentForm: any): FormData {
        let frm = new FormData()
        //document Id will be part of payload only when we updating existng pic
        if (documentForm?.documentId)
            frm = this.addFormFieldToReq(frm, 'DocumentId', documentForm?.documentId)

        frm = this.addFormFieldToReq(frm, 'UserId', documentForm?.userId)
        frm = this.addFormFieldToReq(frm, 'DocumentSubTypeId', documentForm?.documentSubTypeId)
        frm = this.addFormFieldToReq(frm, 'DocumentTypeId', documentForm?.documentTypeId)
        frm = this.addFormFieldToReq(frm, 'DocumentNumber', documentForm?.documentNumber)
        frm = this.addFormFieldToReq(frm, 'Document', documentForm?.document)
        frm = this.addFormFieldToReq(frm, 'IsActive', documentForm?.isActive)
        frm = this.addFormFieldToReq(frm, 'IsVerified', documentForm?.isVerified)

        return frm;
    }

    addFormFieldToReq(frm: FormData, field: string, obj: any): FormData {
        if (obj != null && obj != undefined) {
            frm.append(field, obj)
        }
        return frm;
    }
}   