import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastr: ToastrService) { }


  /**
     * success toaster message
     * @param message
     */
    showSuccess(message: string) {
      this.toastr.success(message);
    }

    /**
     * warning toaster message
     * @param message
     */
    showWarning(message: string) {
      this.toastr.warning(message);
    }

    /**
     * error toaster message
     * @param message
     */
    showError(message: string) {
      this.toastr.error(message);
    }

    /**
     * info toaster message
     * @param message
     */
    showInformation(message:string){
      this.toastr.info(message)
    }

}
