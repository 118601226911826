export enum IconList {
    ApplicationLogo = 'Manu bhoomi new logo.svg',

    DefaultUserProfilePic = 'profileDefaultPic.svg',
    AadhaarCardFrontDefaultPic = '',
    AadhaarCardBackDefaultPic = '',
    PanCardDefaultPic = '',
    AddressProodFrontDefaultPic = '',
    AddressProodBackDefaultPic = '',

    AddIcon = 'addImageIcon.svg',
    EditIcon = 'editIcon.svg',
    PaymentTransaction = 'transactionUPI.png',
    UserIcon = 'userIcon.svg',
    addbuttonIcon = 'addbookingwhite.svg',
    DefaultAadharFrontPic = 'aadharfrnt.png',
    DefaultAadharBackPic = 'aadharback.png',
    DefaultPanCardPic = 'pancard.png',
    DefaultAddProofPic = 'Address Proof.svg',
    DefaultBankCheqPic = 'bank cheq.png',
    DefaultUPIPic = 'UPIDummyimage.svg',
    DefaultCardSwipePic = 'cardswipedummy.svg',
    DefaultIMPSPic = 'IMPSDummyImage.svg',
    exportButtonIcon = 'exportwhite.svg',
    addIconPaymentPlans = 'addIconPayment.svg',
    deleteIconPaymentPlans = 'deleteIconPayment.svg',
    AddbuttonIcon = 'addbookingwhite.svg',
    ActionEditIcon = 'actionEdit.svg',
    ActionDeleteIcon = 'actionDelete.svg',
    PaymentIcon = 'payment icon.svg',
    DocumentIcon = 'Document Icon.svg',
    EditIconDropdown = 'EditIcon(2).svg',
    BookingIcon = 'booking.svg',
    AllotmentIcon = 'allotment icon.svg',
    AllotmentCancelIcon = "allotment-cancel-icon.svg",
    ReconImageGreen = "Amount Icon Green.svg",
    ReconImageRed = "Amount Icon Red.svg",
    ReconImageBlack = "Amount Icon Black.svg",
    TransferIcon = "transfericon.svg",
    ExportWhiteIcon = "exportwhite.svg",
    DeleteIcon = "deleteIcon.svg",
    receivedMoneyIcon = "receivedMoneyIcon.svg"
}
