export const environment = {
  production: false,
  env: "dev",
  apiEndpoint: 'https://devapi.crm.manubhoomi.info',
  // apiEndpoint: 'https://localhost:7142',


  //to fetch data from actual API just set it anything except 'dummy' or set it to blank
  isDummyDataSource: false,
  dummyAPIUrl: "http://localhost:3000",

  decumentVerificationSource: 'deepvue',
  deepVueAPIUrl: 'https://production.deepvue.tech/v1/',
  assetsImagePath: "assets/images/"
};
